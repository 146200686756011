export default class Shohin {
  id: string;
  code: string;
  nm1: string;
  ckbn: string;
  shohinList: Array<{
    edano: string;
    shonm: string;
  }>;
  constructor(id = "", code = "", nm1 = "", ckbn = "", shohinList = []) {
    this.id = id;
    this.code = code;
    this.nm1 = nm1;
    this.ckbn = ckbn;
    this.shohinList = shohinList;
  }
}
