<template>
  <div>
    <h1 class="h2">{{ Title }}</h1>

    <div class="my-5">
      <Btn flat color="primary" icon="backspace" @click="back">戻る</Btn>
      <Btn
        class="ml-3"
        color="info"
        icon="details"
        :disabled="edit"
        @click="edit = true"
        >編集</Btn
      >
    </div>

    <v-form
      v-model="valid"
      ref="form"
      :disabled="!edit"
      @submit.prevent="submit"
    >
      <template>
        <v-text-field
          v-model="code"
          label="大臣コード"
          prepend-icon="mdi-file-table"
          outlined
          :readonly="canadd()"
          :rules="[Rules.Required]"
        ></v-text-field>
        <v-text-field
          v-model="nm1"
          label="大臣名称"
          prepend-icon="mdi-file-table"
          outlined
          :readonly="canadd()"
          :rules="[Rules.Required]"
        ></v-text-field>
        <v-row no-gutters v-for="(item, i) in shohinList" :key="i">
          <v-text-field
            v-model="shohinList[i].shonm"
            class="mr-5"
            :label="'商品名称 ' + (i + 1)"
            outlined
            dense
            prepend-icon="mdi-close"
            @click:prepend="clickDelRow(i)"
            required
            :rules="[Rules.Required, shohinListRules, shohinNameRule]"
          />
        </v-row>
        <v-btn
          rounded
          color="primary"
          small
          @click="addShohin"
          :disabled="!edit"
        >
          <v-icon>mdi-plus</v-icon>商品名称追加
        </v-btn>
      </template>
      <div class="d-flex flex-row-reverse">
        <v-btn type="login" color="success" :disabled="!edit">
          完了
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import Common from "@/mixins/common";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import Shohin from "@/models/Shohin";

export default {
  name: "ShohinDetails",
  mixins: [Common, Forms, Api, ShowDialogs],
  props: {
    title: String,
    prosess: String,
    items: Array,
    args: Object
  },
  data() {
    return {
      params: null,
      add: false,
      edit: false,
      id: "",
      edano: "",
      shonm: "",
      code: "",
      nm1: "",
      ckbn: "",
      onflg: true,
      subHeaders: [],
      subDetails: [],
      shohinList: [{ edano: 1, shonm: "" }],
      shohinListRules: value => {
        const repShohin = this.shohinList.map(ele =>
          ele.shonm.replace(/\s*/g, "")
        );
        const repVal = value.replace(/\s*/g, "");
        const shohinReg = new RegExp("^" + repVal + "$", "i");
        const check = repShohin.map(ele => shohinReg.test(ele));
        return (
          check.indexOf(true) === check.lastIndexOf(true) ||
          "商品名が重複しています。"
        );
        //const map = this.shohinList.map(ele => ele.shonm);
        //const uniqueArr = Array.from(new Set(arr));
        //return JSON.stringify(arr) === JSON.stringify(uniqueArr) || '商品名が重複しています。';
      },
      shohinNameRule: value => {
        const errorValue = "商品名を１５０文字以内で入力してください。";
        return value.length <= 150 || errorValue;
      }
    };
  },
  computed: {
    Title() {
      return (
        this.params.title +
        " " +
        (this.add ? "追加" : this.edit ? "編集" : "詳細")
      );
    }
  },
  methods: {
    canEdit(param) {
      const authority = this.$store.state.authority;
      const user = this.$store.state.userincode;
      if (authority === "1" || authority === "2") {
        if (param === "pass") {
          if (this.userId === user) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    canadd() {
      if (this.add === true) {
        return false;
      } else {
        return true;
      }
    },
    complete() {
      console.log("complete", this.userId);
      if (!this.$refs.form.validate()) return;
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      console.log("submit", this.id);
      this.$loading();

      const shonm = this.shohinList.map(ele => ele.shonm).join(",");
      const edano = this.shohinList.map(ele => ele.edano).join(",");
      console.log(this.shohinList);
      try {
        const param = new Shohin(
          this.id,
          this.code,
          this.nm1,
          this.ckbn,
          this.shohinList
        );

        console.log("submit_param", param);
        if (this.add) {
          //await this.postRecord(param);
          const data = await this.$post(this.Paths.shohin, param);
        } else {
          //await this.putRecord(param);
          await this.$put(this.Paths.shohin, param);
        }
        this.$info("更新しました。", "商品名称マスタ");
        this.edit = false;
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async clickDelRow(i) {
      if (this.shohinList[i].shonm) {
        const msg = `商品 ${i + 1} を削除します。\nよろしいですか？`;
        if (!(await this.$deleteConfirm(msg))) return;
      }
      this.shohinList.splice(i, 1);
      //this.$refs.form.validate();
    },
    addShohin() {
      let index = 1;
      const edanoArr = this.shohinList.map(ele => ele.edano);
      if (edanoArr.length !== 0) {
        console.log(Math.max(...edanoArr) + 1);
        index = Math.max(...edanoArr) + 1;
      }
      this.shohinList.push({ edano: index, shonm: "" });
    },
    back() {
      const path = "/list/shohin";
      // this.$router.push(path);
      this.$router.push({
        path: path,
        query: { filter: this.params.filter }
      });
    }
  },
  async created() {
    console.log(this);
    console.log(this.name, "created");
    this.params = this.$route.params;
    console.log("filter", this.params.filter);
    const test = this.params.args;
    console.log("args", test);

    this.$loading();
    try {
      if (this.params.prosess && typeof this.params.prosess === "string")
        this.params.prosess = this.params.prosess.toLowerCase();

      switch (this.params.prosess) {
        case "add":
          this.add = true;
          this.edit = true;
          break;
        case "edit":
          this.add = false;
          this.edit = true;
          break;
        default:
          break;
      }

      if (!this.add) {
        this.id = test.id;
        this.code = test.code;
        this.nm1 = test.nm1;
        this.ckbn = test.ckbn;

        if (test.shonm !== null && test.edanoChar !== null) {
          const shonmArr = test.shonm.split(",");
          const edanoArr = test.edanoChar.split(",");
          this.shohinList = [];
          for (let i = 0; i < edanoArr.length; i++) {
            this.shohinList.push({
              edano: Number(edanoArr[i]),
              shonm: shonmArr[i]
            });
          }
        }
      }
    } catch (e) {
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  }
};
</script>

<style></style>
